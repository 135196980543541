<template>
    <div style="margin: .625rem; background: #fff;">
        <info-table :showHeader="true" :columns="table.columns" :data="table.data"></info-table>
    </div>
</template>

<script>
import infoTable from '@/components/InfoTable'
import {getDebugUserList} from '@/axios/me'
export default {
    components: {infoTable},
    data(){
        return {
            table: {
                columns: [{name: "userId", text: "序号", formatter: function(_v, _i){
                    return _i + 1
                }}, {name: "userName", text: "姓名"}, {name: "userId", text: "工号"}, {name: "phoneNum", text: "电话"}],
                data: []
            }
        }
    },
    mounted(){
        getDebugUserList({
            equipType: this.$store.getters.equipInfo.equipType
        }).then((res)=>{
            this.table.data = Object.freeze(res.content)
        })
    }  
}
</script>